import React, { Fragment, useCallback, useState, useEffect } from 'react'
import Dialog from '@components/Dialog'
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material'
import moment from 'moment'
import EmailCyclesConfiguration from './EmailCyclesConfiguration'
import TargetCyclesConfiguration from './TargetCyclesConfiguration'
import SessionsCycleJourney from './SessionsCycleJourney'
import WorksheetCyclesConfiguration from './WorksheetCyclesConfiguration'
import MobileCycleSheetConfiguration from './MobileCycleSheetConfiguration'
import PreparationConfiguration from './PreparationConfiguration'
import Card from '@components/Card'
import ParticipantsCycleJourney from './ParticipantsCycleJourney'
import { useTranslation } from 'react-i18next'
import { ContentCopy } from '@mui/icons-material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import GroupIcon from '@mui/icons-material/Group'
import servicePreparation from '@services/servicePreparation'
import CheckBox from '@components/CheckBox'
import Editor from "@components/CkEditor";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SectionCycleJourneysTabs = props => {
  const { t } = useTranslation()
  const {
    formData,
    setFormData,
    cycleJourneys,
    setCycleJourneys,
    consultantsList,
    handleEditedJourneys,
    journeyTypeModeLive,
    setJourneyTypeModeLive,
    setCycleTypeModeLive,
    handleChange
  } = props
  const [showCycleParticipants, setShowCycleParticipants] = useState()
  const [showCycleConfiguration, setShowCycleConfiguration] = useState()
  const [preparations, setPreparations] = useState()
  const [expanded, setExpanded] = useState([])

  const cycleTypeDetail = JSON.parse(sessionStorage.getItem('cycleTypeDetail'));
  
  const cycleTypeJourneys = cycleTypeDetail?.cycleTypeJourneys;
  
  const handleAccordionToggle = (panel) => (e, isExpanded) => {
    setExpanded((prev) => {
      const isCurrentlyExpanded = prev.includes(panel);
      if (isExpanded && !isCurrentlyExpanded) {
        return [...prev, panel];
      } else if (!isExpanded && isCurrentlyExpanded) {
        // Evitar que se cierre el acordeón para ciclos nuevos
        const journeyIndex = parseInt(panel.replace('panel', ''), 10);
        if (!cycleJourneys[journeyIndex]?.cycleJourneyDateFrom) {
          return prev;
        }
        return prev.filter((p) => p !== panel);
      } else {
        return prev;
      }
    });
  };
  

  useEffect(() => {
    // Encuentra los índices de todos los journeys nuevos donde `cycleJourneyDateFrom` no está definido
    const newCycleIndices = cycleJourneys
      .map((journey, index) => (!journey.cycleJourneyDateFrom ? `panel${index}` : null))
      .filter(panel => panel !== null);
  
    // Si hay nuevos journeys, actualiza el estado `expanded` para expandir esos Accordions
    if (newCycleIndices.length > 0) {
      setExpanded((prevExpanded) => [
        ...prevExpanded,
        ...newCycleIndices.filter(panel => !prevExpanded.includes(panel))
      ]);
    }
  }, [cycleJourneys]);
  

  // useEffect(() => {
  // setJourneyTypeModeLive(prevState =>
  //   prevState.map((j, i) => (
  //     j = cycleJourneys.idCycleJourneyModality
  //   ))
  //   )
  // }, [cycleJourneys])

  useEffect(() => {
    servicePreparation.getPreparations({ search: [] }).then(response => {
      if (!response.error) {
        setPreparations(response.data)
      }
    })
  }, [])

  useEffect(() => {    
    journeyTypeModeLive.forEach((mode, i) => {
      setFormData((prevState) => ({
        ...prevState,
        cycleJourneys: prevState.cycleJourneys.map((journey, index) =>
          index === i ? { ...journey, idCycleJourneyModality: mode } : journey
        ),
      }));
    });

    if (journeyTypeModeLive.indexOf(2) === -1) {
      return setCycleTypeModeLive(1)
    } else if (journeyTypeModeLive.indexOf(1) === -1) {
      return setCycleTypeModeLive(2)
    } else {
      return setCycleTypeModeLive(3)
    }
  }, [journeyTypeModeLive])

  // useEffect(() => {
  //   journeyTypeModeLive.forEach((mode, i) => {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       cycleJourneys: prevState.cycleJourneys.map((journey, index) =>
  //         index === i ? { ...journey, idCycleJourneyModality: mode } : journey
  //       ),
  //     }));
  //   });
  // }, [journeyTypeModeLive]);
  // cycleJourneys


  const getNewCycleJourneys = useCallback(
    (newValues, key, index) => {
      handleEditedJourneys()
      const newJourneys = cycleJourneys.map((journey, i) => {
        if (i === index) {
          return {
            ...journey,
            [key]: newValues
          }
        }
        return journey
      })
      return newJourneys
    },
    [cycleJourneys, handleEditedJourneys]
  )

    const handleChangeJourney = (e, index, idInput) => {
        const cycleTypeJourney = cycleTypeJourneys[index]
        const cycleTypeJourneyDIdPreparation = cycleTypeJourney?.cycleTypeJourneyDIdPreparation;
        const cycleTypeJourneyPIdPreparation = cycleTypeJourney?.cycleTypeJourneyPIdPreparation;
      
        setTimeout(
            function () {
                var input = document.querySelector(`#${idInput}`);
                if (input) {
                    input.focus();
                }
            }.bind(this),
            1
        );

        handleEditedJourneys();
        
        let data;

        if (typeof e === 'string') {
            // Si el primer argumento es una cadena, significa que proviene del editor.
            // En este caso, 'e' es el valor del editor.
            data = e;
        } else {
            // Si el primer argumento es un evento ('e'), se trata de un campo de entrada.
            // En este caso, obtenemos el valor del evento.
            data = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        }
        
        setCycleJourneys(prevState =>
          prevState.map((j, i) => {
            if (i === index) {
              if (typeof e === 'string') {
                return { ...j, [idInput]: data };
              } else {
                if (idInput === `modality-${index}`) {
                  const numericData = parseInt(data, 10);
                  if (numericData === 1) {
                    return {
                      ...j,
                      [e.target.name]: data,
                      idPreparation: cycleTypeJourneyPIdPreparation, // Cambiamos idPreparation
                    };
                  } else if (numericData === 2) {
                    return {
                      ...j,
                      [e.target.name]: data,
                      idPreparation: cycleTypeJourneyDIdPreparation, // Cambiamos idPreparation
                    };
                  }
                } else {
                  return { ...j, [e.target.name]: data };
                }
              }
            } else {
              return j;
            }
          })
        );

        if (idInput === `modality-${index}`) {
            setJourneyTypeModeLive(prevState =>
                prevState.map((j, i) => (i === index ? data : j))
            );

            handleChange(e);
        }
    };


    const handleCopyData = () => {
        const values = cycleJourneys[0]
        const newCycleJourneys = cycleJourneys.map(journey => {
          return {
            ...journey,
            cycleJourneyAddress: values.cycleJourneyAddress,
            cycleJourneyCityCode: values.cycleJourneyCityCode,
            cycleJourneyCityShortCode: values.cycleJourneyCityShortCode,
            cycleJourneyContactName: values.cycleJourneyContactName,
            cycleJourneyContactPhone: values.cycleJourneyContactPhone,
            cycleJourneyPrivate: values.cycleJourneyPrivate,
            cycleJourneyDetails: values.cycleJourneyDetails,
            cycleJourneyDetailsURL: values.cycleJourneyDetailsURL,
            idConsultant: values.idConsultant
          }
        })

        setCycleJourneys(newCycleJourneys)
    }

  return (
    <Fragment>
      {showCycleParticipants && (
        <Dialog
          open={showCycleParticipants ? true : false}
          width='50%'
          height='75%'
          hasCloseBtn={false}
          title={t('CYCLES.JOURNEYS.EDIT_ASSISTANTS')}
          close={() => setShowCycleParticipants()}
          content={
            <ParticipantsCycleJourney
              journeyIndex={showCycleParticipants?.journeySelected}
              getNewJourneys={getNewCycleJourneys}
              data={cycleJourneys}
              setData={setCycleJourneys}
              setFormData={setFormData}
              formData={formData}
              setShowCycleParticipants={setShowCycleParticipants}
              setLoading={props.setLoading}
            />
          }
          actions={
            <Box display='flex' width='100%' justifyContent='flex-end'>
              <Button
                variant='contained'
                onClick={() => setShowCycleParticipants()}
                aria-label='move selected left'
              >
                Ok
              </Button>
            </Box>
          }
        />
      )}

      {showCycleConfiguration && (
        <Dialog
          open={showCycleConfiguration ? true : false}
          width={'50%'}
          hasCloseBtn={false}
          title={
            <>
              {t('CYCLES.JOURNEYS.EDIT_CONFIG')}
              <Typography
                color="primary.main"
                variant="subtitle1"
                sx={{
                  fontSize: 16,
                  fontWeight: 'bold'        }}
              >
                {t('CYCLES.JOURNEYS.JOURNEY')} {` ${showCycleConfiguration.journeySelected + 1}`} -{' '}
                {cycleJourneys[showCycleConfiguration.journeySelected]?.cycleJourneyDateFrom
                  ? moment(cycleJourneys[showCycleConfiguration.journeySelected].cycleJourneyDateFrom).format(
                      'DD/MM/YYYY HH:mm'
                    )
                  : ''}
              </Typography>
            </>
          }
          close={() => setShowCycleConfiguration(false)}
          content={
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12}>
                <Card fullHeight>
                  <PreparationConfiguration
                    {...props}
                    preparations={preparations}
                    journeyIndex={showCycleConfiguration.journeySelected}
                    getNewJourneys={getNewCycleJourneys}
                    data={cycleJourneys}
                    setData={setCycleJourneys}
                  />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card fullHeight>
                  <EmailCyclesConfiguration
                    {...props}
                    journeyIndex={showCycleConfiguration.journeySelected}
                    getNewJourneys={getNewCycleJourneys}
                    data={cycleJourneys}
                    setData={setCycleJourneys}
                  />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card fullHeight>
                  <TargetCyclesConfiguration
                    {...props}
                    journeyIndex={showCycleConfiguration.journeySelected}
                    getNewJourneys={getNewCycleJourneys}
                    data={cycleJourneys}
                    setData={setCycleJourneys}
                  />
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card fullHeight>
                  <WorksheetCyclesConfiguration
                    {...props}
                    journeyIndex={showCycleConfiguration.journeySelected}
                    getNewJourneys={getNewCycleJourneys}
                    data={cycleJourneys}
                    setData={setCycleJourneys}
                  />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card fullHeight>
                  <MobileCycleSheetConfiguration
                    {...props}
                    journeyIndex={showCycleConfiguration.journeySelected}
                    getNewJourneys={getNewCycleJourneys}
                    data={cycleJourneys}
                    setData={setCycleJourneys}
                  />
                </Card>
              </Grid>
            </Grid>
          }
          actions={
            <Box display='flex' width='100%' justifyContent='flex-end'>
              <Button
                variant='contained'
                onClick={() => setShowCycleConfiguration(false)}
                aria-label='move selected left'
              >
                Ok
              </Button>
            </Box>
          }
        />
      )}

      {cycleJourneys.map((journey, i, errorCheck) => {
        const prevJourney = errorCheck[i - 1]
        // let valueModeCodeShown = journey.idCycleJourneyModality

        return (
          <Grid key={`journey-${i}`} item xs={12} lg={4}>
            <Card px={2}>
              <Grid container spacing={1} px={1}>
                <Typography
                  color='white'
                  variant='subtitle1'
                  sx={{
                    color: 'primary.main',
                    fontSize: 16,
                    fontWeight: 'bold'
                  }}
                >
                  {t('CYCLES.JOURNEYS.JOURNEY')} {` ${i + 1}`} -{' '}
                  {journey?.cycleJourneyDateFrom
                    ? moment(journey?.cycleJourneyDateFrom).format(
                        'DD/MM/YYYY HH:mm'
                      )
                    : ''}
                </Typography>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item lg={12} xs={12}>
                      <SessionsCycleJourney
                        {...props}
                        journeyIndex={i}
                        getNewJourneys={getNewCycleJourneys}
                        data={cycleJourneys}
                        setData={setCycleJourneys}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item lg={6} xs={12}>
                        <Typography variant='caption'>
                          {t('CYCLES.JOURNEYS.JOURNEY_TYPE')}*
                        </Typography>
                        <Box display='flex' alignItems='center' flexWrap='wrap'>
                          <Select
                            fullWidth
                            size='small'
                            name='idCycleJourneyModality'
                            value={journeyTypeModeLive[i] || '1'}
                            id={`modality-${i}`}
                            onChange={e => {
                              handleChangeJourney(e, i, `modality-${i}`)
                            }}
                          >
                            {[
                              { title: t('LABEL.ONSITE'), id: 1 },
                              { title: t('LABEL.DIGITAL'), id: 2 }
                            ].map(journeyType => (
                              <MenuItem
                                key={`modality${journeyType.id}`}
                                value={journeyType.id}
                              >
                                {journeyType.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <Typography variant='caption'>
                          {t('CYCLES.JOURNEYS.CONSULTANT')}*
                        </Typography>
                        <Box display='flex' alignItems='center' flexWrap='wrap'>
                          <Select
                              error={!consultantsList.some(consultant => consultant.idConsultant === journey?.idConsultant)}
                              fullWidth
                              size='small'
                              name='idConsultant'
                              value={journey?.idConsultant || ''}
                              id={`idConsultant`}
                              style={{color: !consultantsList.some(consultant => consultant.idConsultant === journey?.idConsultant) ? 'darkred' : 'inherit'}}
                              onChange={e => {
                                  handleChangeJourney(e, i, `idConsultant-${i}`)
                              }}
                          >
                              {consultantsList.map(consultant => (
                                  <MenuItem
                                      key={consultant.idConsultant}
                                      value={consultant.idConsultant}
                                  >
                                      {consultant.consultantName} {consultant.consultantSurname}
                                  </MenuItem>
                              ))}
                              {!consultantsList.some(consultant => consultant.idConsultant === journey?.idConsultant) && (
                                  <MenuItem
                                      key={journey?.idConsultant}
                                      value={journey?.idConsultant}
                                      style={{ color: 'darkred' }}
                                  >
                                      {journey?.consultantName} {journey?.consultantSurname} ({t('CONSULTANT.INACTIVE')})
                                  </MenuItem>
                              )}
                          </Select>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                <Grid item display='flex' alignItems='end' sm={6}>
                  <CheckBox
                    id={`cycleTarget-${i}`}
                    label={t('BUTTON.ACTIVATE_TARGETS')}
                    name='cycleTarget'
                    value={journey.cycleTarget || false}
                    checked={journey.cycleTarget || false}
                    onChange={e => {
                      handleChangeJourney(e, i, `cycleTarget-${i}`)
                    }}
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  />
                </Grid>

                <Grid item display='flex' alignItems='end' sm={6}>
                    <CheckBox
                        id={`activeAssesment-${i}`}
                        label={t('BUTTON.ACTIVATE_ASSESSMENT')}
                        name='activeAssesment'
                        value={journey.activeAssesment || false}
                        checked={journey.activeAssesment || false}
                        onChange={e => {
                            handleChangeJourney(e, i, `activeAssesment-${i}`)
                        }}
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    />
                </Grid>

                <Grid item xs={12} mt={1}>
                  <ButtonGroup disableElevation variant='contained' fullWidth>
                    <Button
                      startIcon={<GroupIcon />}
                      sx={{
                        textTransform: 'none'
                      }}
                      onClick={() => {
                        setShowCycleParticipants({ journeySelected: i })
                      }}
                    >
                      {t('CYCLES.JOURNEYS.PARTICIPANTS')} -{' '}                      
                      {journey.cycleJourneyParticipants.filter(participant => participant.cycleJourneyAssistance === true).length}
                    </Button>

                    <Button
                      startIcon={<SettingsOutlinedIcon />}
                      sx={{
                        textTransform: 'none'
                      }}
                      onClick={() => {
                        setShowCycleConfiguration({ journeySelected: i })
                      }}
                    >
                      {t('CYCLES.JOURNEYS.CONFIGURATION')}
                    </Button>
                  </ButtonGroup>
                </Grid>

                
                <Accordion
                    expanded={expanded.includes(`panel${i}`)}
                    onChange={handleAccordionToggle(`panel${i}`)}
                    sx={{ width: '100%', marginTop: '10px' }}
                  >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon />        
                    }
                    aria-controls={`panel${i}bh-content`}
                    id={`panel${i}bh-header`}
                    sx={{
                      flexDirection: 'row-reverse',
                      backgroundColor: 'grey.main',
                      color: 'white',
                      minHeight: 48,
                      '&.Mui-expanded': {
                        minHeight: 48,
                      },
                      '& .MuiAccordionSummary-content': {
                        margin: '8px',
                        '&.Mui-expanded': {
                          margin: '8px',
                        },
                      },
                      '& .MuiAccordionSummary-expandIconWrapper': {
                        marginRight: '-8px',
                        transform: 'rotate(-90deg)',
                        '&.Mui-expanded': {
                          transform: 'rotate(0deg)',
                        },
                      },
                    }}
                  >
                  <Typography>{t('TEXT.MORE_INFO')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
              


                  {(journeyTypeModeLive[i] == 1) && (
                  <Grid item xs={12}>
                      <Grid container spacing={1}>
                    <Grid item lg={6} xs={12}>
                      <Typography variant='caption'>
                        {t('CYCLES.JOURNEYS.ADDRESS')}
                      </Typography>
                      <Editor
                        data={journey.cycleJourneyAddress || ''}
                        name='cycleJourneyAddress'
                        onChange={e => {
                          handleChangeJourney(e, i, `cycleJourneyAddress`);
                        }}
                        id={`address-${i}`}
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant='caption'>
                            {t('CYCLES.JOURNEYS.CITY')}
                          </Typography>
                          <TextField
                            fullWidth
                            name='cycleJourneyCityCode'
                            value={journey?.cycleJourneyCityCode || ''}
                            size='small'
                            id={`cityCode-${i}`}
                            onChange={e => {
                              handleChangeJourney(e, i, `cityCode-${i}`);
                            }}
                            inputProps={{ maxLength: 50 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='caption'>
                            {t('LABEL.CITY_SHORT')}
                          </Typography>
                          <TextField
                            fullWidth
                            name='cycleJourneyCityShortCode'
                            value={journey?.cycleJourneyCityShortCode || ''}
                            size='small'
                            id={`cityShortCode-${i}`}
                            onChange={e => {
                              handleChangeJourney(e, i, `cityShortCode-${i}`);
                            }}
                            inputProps={{ maxLength: 50 }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography variant='caption'>
                      {journeyTypeModeLive[i] == 1
                        ? t('CYCLES.JOURNEYS.DETAILS')
                        : t('CYCLES.JOURNEYS.PLATFORM')}
                    </Typography>

                    <TextField
                      name='cycleJourneyDetails'
                      value={journey.cycleJourneyDetails || ''} //TODO: Este no es el nombre
                      size='small'
                      id={`details-${i}`}
                      onChange={e => {
                        handleChangeJourney(e, i, `details-${i}`)
                      }}
                      fullWidth
                    />
                  </Grid>
                   
                  {journeyTypeModeLive[i] === 2 && (
                    <Grid item xs={6}>
                      <Typography variant='caption'>
                            {t('CYCLES.JOURNEYS.URL')}
                      </Typography>
                      <TextField
                        name='cycleJourneyDetailsURL'
                        value={journey.cycleJourneyDetailsURL || ''}
                        size='small'
                        id={`detailsURL-${i}`}
                        onChange={e => {
                          handleChangeJourney(e, i, `detailsURL-${i}`)
                        }}
                        fullWidth
                      />
                    </Grid>
                  )}


                  <Grid item xs={6}>
                    <Typography variant='caption'>
                      {t('CYCLES.JOURNEYS.CONTACT')}
                    </Typography>

                    <TextField
                      name='cycleJourneyContactName'
                      value={journey.cycleJourneyContactName || ''}
                      size='small'
                      id={`contactName-${i}`}
                      onChange={e => {
                        handleChangeJourney(e, i, `contactName-${i}`)
                      }}
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='caption'>
                      {t('CYCLES.JOURNEYS.PHONE')}
                    </Typography>

                    <TextField
                      name='cycleJourneyContactPhone'
                      value={journey.cycleJourneyContactPhone || ''}
                      size='small'
                      id={`contactPhone-${i}`}
                      onChange={e => {
                        handleChangeJourney(e, i, `contactPhone-${i}`)
                      }}
                      fullWidth
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                      <Typography variant='caption'>
                          {t('LABEL.POSTIT')}
                      </Typography>
                      <Editor
                          data={journey.cycleJourneyRemarks || ''}
                          name='cycleJourneyRemarks'
                          onChange={e => {
                              handleChangeJourney(e, i, `cycleJourneyRemarks`)
                          }}
                          id={`remarks-${i}`}
                      />
                              {/*<TextField
                          name='cycleJourneyRemarks'
                          value={journey.cycleJourneyRemarks || ''}
                          size='small'
                          rows={6}
                          multiline
                          fullWidth
                          id={`remarks-${i}`}
                          onChange={e => {
                              handleChangeJourney(e, i, `remarks-${i}`)
                          }}
                      />*/}
                  </Grid>

                </AccordionDetails>
              </Accordion>

              {i === 0 && cycleJourneys.length > 1 && (
                    <Button
                      startIcon={<ContentCopy />}
                      fullWidth
                      color='success'
                      variant='contained'
                      sx={{
                        textTransform: 'none',
                        mt: 2
                      }}
                      onClick={() => handleCopyData()}
                    >
                      {t('CYCLES.JOURNEYS.COPY')}
                    </Button>
                  )}

              </Grid>
            </Card>
          </Grid>
        )
      })}
    </Fragment>
  )
}

export default SectionCycleJourneysTabs
